<template>
  <div class="training">
    <v-container class="mt-10" v-if="training">
      <v-breadcrumbs :items="navigation" large class="pl-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <h1 class="mb-5">{{ training.titel }}</h1>
      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <h5 class="text-uppercase font-weight-regular">
                    {{ $t("trainingView.summary") }}
                  </h5>
                  <h3>{{ training.lead }}</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h5 class="text-uppercase font-weight-regular">
                    {{ $t("trainingView.content") }}
                  </h5>
                  <h3
                    v-for="(paragraph, index) in training.inhalt"
                    :key="index"
                  >
                    {{ paragraph }}
                    <br />
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="3">
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.format") }}
                  </h5>
                  <h3>{{ training.format[locale] }}</h3>
                </v-col>
                <v-col cols="6" md="3">
                  <h5
                    v-show="training.bildungsstufe !== undefined"
                    class="text-uppercase font-weight-regular mt-5"
                  >
                    {{ $t("trainingView.educationStage") }}
                  </h5>
                  <h3>{{ training.bildungsstufe }}</h3>
                </v-col>
                <v-col cols="6" md="3">
                  <h5
                    v-show="training.umfang !== undefined"
                    class="text-uppercase font-weight-regular mt-5"
                  >
                    {{ $t("trainingView.scope") }}
                  </h5>
                  <h3 v-for="umfang in training.umfang" :key="umfang">
                    {{ umfang }}
                  </h3>
                </v-col>
                <v-col cols="6" md="3">
                  <h5
                    v-if="training.ects"
                    class="text-uppercase font-weight-regular mt-5"
                  >
                    {{ $t("trainingView.ects") }}
                  </h5>
                  <h3 v-for="ects in training.ects" :key="ects">
                    {{ ects }}
                  </h3>
                </v-col>
                <v-col cols="6" md="3">
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.degree") }}
                  </h5>
                  <h3>{{ training.abschluss[locale] }}</h3>
                </v-col>
                <v-col cols="6" md="3">
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.language") }}
                  </h5>
                  <h3
                    v-for="(sprache, index) in training.sprachen[locale]"
                    :key="index"
                  >
                    {{ sprache }}
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card height="100%">
            <v-card-title>{{ $t("trainingView.requirements") }} </v-card-title>
            <v-card-text>
              <h5 class="text-uppercase font-weight-regular mt-5">
                {{ $t("trainingView.degreesRequired") }}
              </h5>
              <h3>
                <ul>
                  <li
                    v-for="abschluss in training.zulassungsbedingungenAbschluss[
                      locale
                    ]"
                    :key="abschluss"
                  >
                    {{ abschluss }}
                  </li>
                </ul>
              </h3>

              <h5 class="text-uppercase font-weight-regular mt-5">
                {{ $t("trainingView.degreesRequiredSpecific") }}
              </h5>
              <h3>
                <ul
                  v-show="training.zulassungsbestimmungenSpezifischerAbschluss"
                >
                  <li
                    v-for="abschluss in training.zulassungsbestimmungenSpezifischerAbschluss"
                    :key="abschluss"
                  >
                    {{ abschluss }}
                  </li>
                </ul>
              </h3>
              <h3
                v-show="!training.zulassungsbestimmungenSpezifischerAbschluss"
              >
                {{ $t("trainingView.none") }}
              </h3>

              <h5 class="text-uppercase font-weight-regular mt-5">
                {{ $t("trainingView.degreesRequiredTopic") }}
              </h5>
              <h3>
                <ul>
                  <li
                    v-for="themenfeld in training.zulassungsbestimmungenAbschlussThemenfelder"
                    :key="themenfeld"
                  >
                    {{ themenfeld }}
                  </li>
                </ul>
              </h3>
              <h3
                v-show="!training.zulassungsbestimmungenAbschlussThemenfelder"
              >
                {{ $t("trainingView.none") }}
              </h3>

              <h5 class="text-uppercase font-weight-regular mt-5">
                {{ $t("trainingView.experienceRequired") }}
              </h5>
              <h3>{{ training.zulassungsbedingungenBerufserfahrung }}</h3>
              <h3 v-show="!training.zulassungsbedingungenBerufserfahrung">
                {{ $t("trainingView.none") }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card height="100%">
            <v-card-title>
              {{ $t("trainingView.directConnectionOptions") }}
            </v-card-title>
            <v-card-text>
              <h3>
                <ul>
                  <li
                    v-for="abschluss in training.direkteAnschlussmoeglichkeitenAbschluesse"
                    :key="abschluss"
                  >
                    {{ abschluss }}
                  </li>
                </ul>
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>{{ $t("trainingView.provider") }}</h2>
        </v-col>
      </v-row>

      <v-row v-for="(anbieter, index) in training.anbieter" :key="index">
        <v-col cols="12">
          <v-card>
            <v-card-title
              v-for="name in anbieter.name"
              :key="name"
              class="d-flex flex-column text-left justify-start align-start"
            >
              <h6 class="text-uppercase font-weight-regular">
                {{
                  anbieter.traegerschaft
                    ? $t("trainingView.sponsorInstitution")
                    : $t("trainingView.provider")
                }}
              </h6>
              <h2>{{ name }}</h2>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.region") }}
                  </h5>
                  <h3 v-for="region in anbieter.region[locale]" :key="region">
                    {{ region }}
                  </h3>
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.language") }}
                  </h5>
                  <h3
                    v-for="sprache in anbieter.sprache[locale]"
                    :key="sprache"
                  >
                    {{ sprache }}
                  </h3>
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.price") }}
                  </h5>
                  <h3>{{ anbieter.preis }}</h3>
                  <h5 class="text-uppercase font-weight-regular mt-5">
                    {{ $t("trainingView.contact") }}
                  </h5>
                  <h3
                    v-for="adressZeile in anbieter.adresse"
                    :key="adressZeile"
                  >
                    {{ adressZeile }}
                  </h3>
                  <br />
                  <h3 v-if="anbieter.email">
                    <v-icon class="mr-4">mdi-email</v-icon>
                    <a :href="'mailto:' + anbieter.email.trim()">
                      {{ anbieter.email.trim() }}
                    </a>
                  </h3>
                  <h3 v-if="anbieter.telefon">
                    <v-icon class="mr-4">mdi-phone</v-icon>
                    <a :href="'tel:' + anbieter.telefon.trim()">
                      {{ anbieter.telefon.trim() }}
                    </a>
                  </h3>
                  <h3 v-if="anbieter.website">
                    <v-icon class="mr-4">mdi-earth</v-icon>
                    <a :href="anbieter.website.trim()">
                      {{ $t("trainingView.goToWebsite") }}
                    </a>
                  </h3>
                </v-col>
                <v-col cols="12" md="6">
                  <iframe
                    v-if="anbieter.adresse"
                    width="100%"
                    height="100%"
                    id="gmap_canvas"
                    :src="googleMapsURL(anbieter.adresse)"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                  <h3 v-if="!anbieter.adresse">
                    {{ $t("trainingView.noAddressAvailable") }}
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ViewTraining",
  computed: {
    training() {
      return this.$store.getters["trainings/trainings"].find(
        o => o.id == this.$route.params.id
      );
    },
    navigation() {
      return [
        {
          text: this.$t("trainings"),
          link: true,
          exact: true,
          to: { name: "trainings" }
        },
        {
          text: this.training.titel,
          link: true,
          disabled: true
        }
      ];
    },
    locale() {
      return this.$root.$i18n.locale;
    }
  },
  methods: {
    googleMapsURL(adresse) {
      const params = {
        t: "",
        ie: "UTF8",
        iwloc: "",
        output: "embed",
        ll: "46.862864,8.032476",
        z: 7,
        q: adresse
      };

      let query = Object.keys(params)
        .map(key => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&");
      return `https://maps.google.com/maps?${query}`;
    }
  }
};
</script>
